import SvgCheckboxCheckedIcon from 'assets/icons/CheckboxChecked';
import SvgCheckboxUnCheckedIcon from 'assets/icons/CheckboxUnChecked';
import SortChevron from 'assets/icons/sort-arrow.svg';
import cn from 'classnames';
import debounce from 'lodash/debounce';
import get from 'lodash/get';
import omit from 'lodash/omit';
import keys from 'lodash/keys';
import mapValues from 'lodash/mapValues';
import keyBy from 'lodash/keyBy';

import React, { Dispatch, ReactNode, useCallback, useContext, Fragment, useState, useEffect, useMemo } from 'react';
import Table from 'react-md/lib/DataTables/DataTable';
import TableBody from 'react-md/lib/DataTables/TableBody';
import TableColumn from 'react-md/lib/DataTables/TableColumn';
import TableHead from 'react-md/lib/DataTables/TableHeader';
import TableRow from 'react-md/lib/DataTables/TableRow';
import Checkbox from 'shared/components/Checkbox';

import uuid from 'uuid/v4';
import { BoxProps } from 'shared/components/Box';
import { ColumnSort, Identifiable } from 'shared/types';
import styled, { ThemeContext } from 'styled-components';
import {
  backgroundImage,
  border,
  color,
  compose,
  flexbox,
  layout,
  overflow,
  position,
  space,
  typography,
} from 'styled-system';
import Column, { ColumnType } from './Column';
import EmptyTable from './EmptyTable';
import Pagination from './Pagination';
import { TableAction, TableState } from './tableReducer';
import Button from 'shared/components/Button';
import { loadingState, getRandomInt } from './utils';
import { LoadingSkeleton } from './styledComponents';

export type SelectedRow = {
  [key: string]: boolean;
};

export type ExpandedRowProps<T> = {
  index: number;
  row: T;
};

type DataTableProps<T extends Identifiable> = {
  rows: T[];
  customRowRenderer?: React.FC<any>;
  additionalRows?: (rows: T[], columns: ColumnType<T>[], hasCheckbox?: boolean) => ReactNode;
  columns: ColumnType<T>[];
  onRowClick: (row: any) => void;
  className: string;
  isSelectable: boolean;
  isLocalSelectable?: boolean;
  isSimpleTable?: boolean;
  onSort: (column: string) => void;
  sort: ColumnSort[];
  onRowToggle: () => void;
  onRowSelect?: (rows: SelectedRow) => void;
  pageSizes: number[];
  showPagination?: boolean;
  tableState?: TableState;
  tableDispatch?: Dispatch<TableAction<any>>;
  rowCount?: number;
  loading?: boolean;
  rowClassName?: (row: T) => string;
  checkedItems?: string[];
  rowCheckboxEnabled?: (row: T) => boolean;
  rowCheckboxDisabled?: (row?: T) => boolean;
  stickyHeader?: boolean;
  markDanger?: boolean;
  fixedHeight?: number;
  bulkActionLabel?: React.FC<any>;
  bulkAction?: React.FC<any>;
  showMore?: boolean;
  showEmptyTable?: boolean;
  lastPage?: number;
  baseId?: string;
  queryUrl?: string;
  queryHandlers?: any;
  defaultExpandState?: boolean;
  shouldExpandRow?: (row: T, index: number) => boolean;
  renderExpandedRow?: React.FC<ExpandedRowProps<T>>;
  resetOnChangeRows?: boolean;
  hideTableHeader?: boolean;
  paddingBottom?: string;
  mobilePaddingBottom?: string;
  editedRowIndex?: number;
  bulkActionExtraProps?: any;
  isBorderHidden?: boolean;
  withAvatar?: boolean;
} & EmptyTableProps;

export type EmptyTableProps = {
  emptyRowText?: string;
  emptyRowSubText?: string | string[];
  emptyRowActions?: ReactNode[];
  emptyRowIcon?: ReactNode;
};

function DataTable<T extends Identifiable>(props: DataTableProps<T>) {
  const {
    rows,
    customRowRenderer: CustomRowRenderer,
    additionalRows,
    columns,
    onRowClick,
    className,

    showEmptyTable = true,

    isSimpleTable = false,
    isSelectable,
    isLocalSelectable = false,
    onRowSelect,

    showPagination,
    tableState,
    tableDispatch,
    pageSizes,
    rowCount,
    loading = true,
    rowClassName = () => '',
    bulkAction: BulkAction = () => null,
    bulkActionLabel: BulkActionLabel,
    resetOnChangeRows,
    renderExpandedRow: ExpandedRow = () => null,
    shouldExpandRow = () => false,
    defaultExpandState = false,

    checkedItems,
    rowCheckboxEnabled = () => false,
    rowCheckboxDisabled = () => false,
    stickyHeader,
    markDanger = false,
    emptyRowText,
    emptyRowSubText,
    emptyRowActions = [],
    showMore = false,
    lastPage = 0,
    baseId,
    emptyRowIcon,
    hideTableHeader,
    paddingBottom = '',
    mobilePaddingBottom = '',
    editedRowIndex,
    bulkActionExtraProps,
    isBorderHidden = false,
    withAvatar = false,
  } = props;

  const [selectedRows, setSelectedRows] = useState<SelectedRow>({});
  const tableID = useMemo(() => {
    return baseId || uuid();
  }, [baseId]);

  const sort = get(tableState, 'sort', []);
  const handleChange = useCallback(debounce(handleClickChange, 100), []);

  const displayedRows = rows;

  const isRowCheckDisabled = rowCheckboxDisabled?.();
  const filteredCheckboxRow = useMemo(() => {
    return displayedRows?.filter(row => !rowCheckboxDisabled?.(row));
  }, [displayedRows]);

  const selectedRowKeys = useMemo(() => keys(selectedRows), [selectedRows]);
  const isAllChecked = useMemo(() => {
    return selectedRowKeys?.length >= filteredCheckboxRow?.length;
  }, [filteredCheckboxRow, selectedRowKeys, isRowCheckDisabled]);

  const headCheckboxEnabled = useMemo(() => {
    const enabled = displayedRows?.filter(row => rowCheckboxEnabled?.(row));
    return enabled?.length > 0 ? true : false;
  }, [displayedRows]);

  const extraprops: any = {};
  const theme = useContext(ThemeContext);

  const handleHeadCheckBoxChange = useCallback(
    (checked: boolean) => {
      if (isRowCheckDisabled) return;

      if (!checked) return setSelectedRows({});
      setSelectedRows(mapValues(keyBy(filteredCheckboxRow, 'id'), () => true));
    },
    [isRowCheckDisabled, filteredCheckboxRow],
  );

  const rowMapper = (row: T, rowIndex: number) => {
    const customRow = Boolean(get(row, 'shouldRenderCustomRow'));
    const rowProps = {
      row,
      rowIndex,
      handleChange,
      rowClassName,
      rowCheckboxEnabled,
      rowCheckboxDisabled,
      shouldExpandRow,
      defaultExpandState,
      tableID,
      selectedRows,
      onLocalRowtoggle,
      isLocalSelectable,
      isSimpleTable,
      rowsLenght: displayedRows?.length,
      selected: checkedItems?.includes(row.id as string),
      markDanger: markDanger && Boolean(row?.status !== 'Active'),
      checkedItems,
      headCheckboxEnabled,
      loading,
      shouldShowBulkAction,
      selectedRowKeys,
      handleHeadCheckBoxChange,
      isAllChecked,
      renderExpandedRow: ExpandedRow,
      BulkActionLabel,
      setSelectedRows,
      editedRowIndex,
      ...props,
    };
    if (customRow && CustomRowRenderer) return <CustomRowRenderer {...rowProps} />;
    return <RenderRow {...rowProps} />;
  };

  if (isSelectable && !isLocalSelectable) extraprops['onRowToggle'] = onRowToggle;

  const checkedItemsLength = checkedItems?.length;
  const selectedRowKeysLength = selectedRowKeys?.length;

  const shouldShowBulkAction: boolean = useMemo(() => {
    return !loading && (Boolean(checkedItemsLength) || Boolean(selectedRowKeysLength));
  }, [loading, checkedItemsLength, selectedRowKeysLength]);

  useEffect(() => {
    loading && setSelectedRows({});
  }, [loading]);

  useEffect(() => {
    onRowSelect && onRowSelect(selectedRows);
  }, [selectedRows]);

  useEffect(() => {
    if (resetOnChangeRows) {
      setSelectedRows({});
    }
  }, [rows]);

  return (
    <>
      {!loading && showEmptyTable && displayedRows?.length === 0 ? (
        <EmptyTable
          emptyRowIcon={emptyRowIcon}
          emptyRowText={emptyRowText}
          emptyRowSubText={emptyRowSubText}
          emptyRowActions={emptyRowActions}
        />
      ) : (
        <StyledTable
          responsive
          fixedHeight
          baseId={`selecteable-table${baseId ? `-${baseId}` : ''}`}
          plain={Boolean(!isSelectable)}
          className={className}
          uncheckedIcon={<SvgCheckboxUnCheckedIcon width="16px" height="16px" />}
          checkedIcon={<SvgCheckboxCheckedIcon width="16px" height="16px" fill={theme.colors.green} />}
          paddingBottom={paddingBottom}
          mobilePaddingBottom={mobilePaddingBottom}
          {...extraprops}
        >
          {((Boolean(!checkedItems?.length) && Boolean(!selectedRowKeys?.length)) || !shouldShowBulkAction) &&
            !hideTableHeader && (
              <StyledTableHead className={cn({ sticky: stickyHeader })} isBorderHidden={isBorderHidden}>
                <TableRow>
                  {isLocalSelectable && (
                    <StyledTableColumn className="md-table-checkbox" adjusted={false} width="0px">
                      <Checkbox
                        className="custom-table-checkbox"
                        name={`table-${tableID}-header`}
                        id={`table-${tableID}-header`}
                        size="small"
                        checked={isAllChecked}
                        disabled={isRowCheckDisabled}
                        onChange={handleHeadCheckBoxChange}
                      />
                    </StyledTableColumn>
                  )}
                  {columns.map(
                    ({ title, accessor = '', headProps = {}, sortable = true, adjusted = false, width }, idx) => {
                      const sorted = sort.find(e => e.column === accessor);
                      return (
                        <StyledTableColumn
                          key={idx}
                          sortIconBefore={false}
                          width={width}
                          sortIcon={
                            <div>
                              <img src={SortChevron} />
                            </div>
                          }
                          onClick={() => {
                            if (tableDispatch && sortable) {
                              const payload: ColumnSort = {
                                column: accessor,
                                direction: !sorted || get(sorted, 'direction') === 'desc' ? 'asc' : 'desc',
                              };
                              tableDispatch({ type: 'SetSort', payload });
                            }
                          }}
                          sorted={sorted && sorted.direction === 'asc'}
                          adjusted={adjusted}
                          {...headProps}
                        >
                          {title}
                        </StyledTableColumn>
                      );
                    },
                  )}
                </TableRow>
              </StyledTableHead>
            )}
          {shouldShowBulkAction && !hideTableHeader && (
            <StyledTableHead className={cn({ sticky: stickyHeader })} isBorderHidden={isBorderHidden}>
              <TableRow>
                {isLocalSelectable && (
                  <StyledTableColumn className="md-table-checkbox" adjusted={false} width="0px">
                    <Checkbox
                      className="custom-table-checkbox"
                      name={`table-${tableID}-header`}
                      id={`table-${tableID}-header`}
                      size="small"
                      checked={isAllChecked}
                      disabled={isRowCheckDisabled}
                      onChange={handleHeadCheckBoxChange}
                    />
                  </StyledTableColumn>
                )}
                <StyledTableColumn
                  sortIconBefore={false}
                  colSpan={columns?.length || 1}
                  className="md-table-column--bulk-action"
                >
                  <div className="md-table-column--count">
                    {!BulkActionLabel && `${(isLocalSelectable ? selectedRowKeys : checkedItems)?.length} selected`}
                    {BulkActionLabel && (
                      <BulkActionLabel checkedItems={isLocalSelectable ? selectedRowKeys : checkedItems} />
                    )}
                  </div>
                  <div>
                    <BulkAction
                      checkedItems={isLocalSelectable ? selectedRowKeys : checkedItems}
                      {...bulkActionExtraProps}
                    />
                  </div>
                </StyledTableColumn>
              </TableRow>
            </StyledTableHead>
          )}
          <StyledTableBody hideTableHeader={hideTableHeader}>
            <>
              {!loading && displayedRows?.length === 0 && (
                <EmptyTableRow>
                  <td colSpan={columns?.length}>No Records Found</td>
                </EmptyTableRow>
              )}
              {loading ? loadingState<T>(columns, 10, isLocalSelectable, withAvatar) : displayedRows?.map(rowMapper)}
              {!loading && additionalRows?.(displayedRows, columns, headCheckboxEnabled)}
              {showMore && tableState?.page !== lastPage && (
                <ShoreMoreContainer>
                  <td colSpan={columns?.length}>
                    <div className="column-content">
                      <Button
                        id="add-box-drawer"
                        variant="secondary"
                        type="submit"
                        isLoading={false}
                        onClick={() => {
                          tableDispatch?.({ type: 'SetPage', payload: (tableState?.page || 0) + 1 });
                        }}
                      >
                        Show More
                      </Button>
                    </div>
                  </td>
                </ShoreMoreContainer>
              )}
            </>
          </StyledTableBody>
        </StyledTable>
      )}
      {showPagination && tableState && tableDispatch && (
        <Pagination
          pageSizes={pageSizes}
          size={tableState.size}
          page={tableState.page}
          count={rowCount || 0}
          setSize={(payload: number) => tableDispatch({ type: 'SetSize', payload })}
          setPage={(payload: number) => tableDispatch({ type: 'SetPage', payload })}
        />
      )}
    </>
  );

  function handleClickChange(row: any, nodeName?: string) {
    if (nodeName !== 'INPUT') onRowClick(row);
  }

  function handleCheck(val: boolean, event: React.ChangeEvent<HTMLInputElement>) {
    event.stopPropagation();
    const { id } = event.target;
    const index = displayedRows.findIndex(e => e.id === id);
    onRowToggle(index + 1, val);
  }

  function onRowToggle(index: number, checked: boolean) {
    tableDispatch && tableDispatch({ type: 'SetSelected', payload: { index, checked, rows: displayedRows } });
  }

  function onLocalRowtoggle(rowID: string | number, checked: boolean) {
    if (!checked) return setSelectedRows(prev => omit(prev, [rowID]));
    setSelectedRows(prev => ({ ...prev, [rowID]: checked }));
  }
}

export type RenderRowProps<T extends Identifiable> = Pick<
  DataTableProps<T>,
  | 'rowClassName'
  | 'rowCheckboxEnabled'
  | 'rowCheckboxDisabled'
  | 'columns'
  | 'defaultExpandState'
  | 'isLocalSelectable'
  | 'isSimpleTable'
> & {
  row: T;
  rowIndex: number;
  rowsLenght: number;
  selected?: boolean;
  markDanger?: boolean;
  handleChange: (row: T, nodeName?: string) => void;
  shouldExpandRow: (row: T, index: number) => boolean;
  renderExpandedRow: React.FC<ExpandedRowProps<T>>;
  tableID: string;
  selectedRows: SelectedRow;
  onLocalRowtoggle: (rowID: string | number, checked: boolean) => void;
  editedRowIndex?: any;
  loading?: boolean;
};

function RenderRow<T extends Identifiable>(props: RenderRowProps<T>) {
  const [expand, expandToggle] = useState(false);
  const [isChecked, setCheckbox] = useState(false);
  const {
    row,
    columns,
    rowIndex,
    selected,
    rowsLenght,
    markDanger,
    handleChange,
    rowClassName,
    rowCheckboxEnabled,
    rowCheckboxDisabled,
    defaultExpandState = false,
    shouldExpandRow,
    renderExpandedRow: ExpandedRow,
    tableID,
    selectedRows,
    onLocalRowtoggle,
    isLocalSelectable,
    isSimpleTable,
    editedRowIndex,
    loading,
  } = props;

  const rowID = useMemo(() => {
    return row?.id || uuid();
  }, [row]);

  const checkboxEnabled = rowCheckboxEnabled?.(row);
  const checkboxDisabled = rowCheckboxDisabled?.(row);

  useEffect(() => {
    expandToggle(defaultExpandState);
  }, [defaultExpandState]);

  const isSelected = useMemo(() => {
    return Boolean(selectedRows[rowID]);
  }, [rowID, selectedRows]);

  useEffect(() => {
    setCheckbox(isSelected);
  }, [isSelected]);

  const shouldExpand = useMemo(() => shouldExpandRow(row, rowIndex), [row, rowIndex]);
  const expandRow = useMemo(() => {
    return shouldExpand && expand && <ExpandedRow row={row} index={rowIndex} />;
  }, [row, rowIndex, expand, shouldExpand]);

  const StyledRow = useMemo(() => {
    return isSimpleTable ? (props: any) => <StyledSimpleTableRow {...props} /> : StyledTableRow;
  }, [isSimpleTable, StyledTableRow, StyledSimpleTableRow]);

  const extraProps = {
    selected: Boolean(selected),
    selectable: isLocalSelectable ? false : checkboxEnabled,
  };

  return (
    <Fragment>
      <StyledRow
        {...(!isSimpleTable ? extraProps : {})}
        key={row.id}
        onClick={(e: any) => {
          e.stopPropagation();
          handleChange(row, e?.target?.nodeName);
        }}
        className={cn('md-table-row', rowClassName?.(row))}
        danger={Boolean(markDanger)}
      >
        {isLocalSelectable && checkboxEnabled && (
          <StyledTableColumn className="md-table-checkbox" adjusted={false}>
            <Checkbox
              checked={isChecked}
              className="custom-table-checkbox"
              disabled={checkboxDisabled}
              name={`table-${tableID}-row-${rowID}`}
              id={`table-${tableID}-row-${rowID}`}
              size="small"
              onChange={(checked: boolean) => {
                if (checkboxDisabled) return;
                setCheckbox(checked);
                onLocalRowtoggle(rowID, checked);
              }}
            />
          </StyledTableColumn>
        )}
        {columns.map((column, idx) => {
          if (rowIndex === editedRowIndex && loading) {
            return (
              <StyledTableColumn>
                <LoadingSkeleton $width={getRandomInt(65, 100)} />
              </StyledTableColumn>
            );
          }
          return (
            <Column
              key={idx}
              row={row}
              column={column}
              index={rowIndex}
              rowLength={rowsLenght}
              expandToggle={expandToggle}
              expand={expand}
              editedRowIndex={editedRowIndex}
            />
          );
        })}
      </StyledRow>
      {expandRow}
    </Fragment>
  );
}

export const StyledTableColumn = styled(TableColumn)<BoxProps>`
  ${compose(space, color, layout, flexbox, backgroundImage, overflow, border, position, typography)}

  &.md-table-column--bulk-action,
  .md-table-column--bulk-action {
    margin-top: 6px;
    display: flex;
    align-items: center;

    & > * {
      display: inline-block;
    }

    button {
      font-weight: normal;
      font-size: 14px;
      line-height: 24px;
    }

    .md-table-column--count {
      margin-right: 24px;
    }

    .md-table-column {
      width: ${({ width }) => width};
    }
  }

  .md-selection-control-container {
    .md-selection-control-toggle {
      margin-right: 0 !important;
      width: 16px !important;
      height: 16px !important;
      box-sizing: border-box !important;
      border-radius: 2px !important;
    }

    svg {
      margin-right: 0px !important;
    }

    span {
      margin-left: 8px;
      font-weight: 500;
      font-size: 16px;
      line-height: 28px;
    }

    .md-selection-control-label {
      display: flex;
      align-items: center;
    }
  }

  .custom-table-checkbox {
    width: 0px !important;

    .md-selection-control-label {
      width: 40px;
      height: 40px;
      box-sizing: border-box;
      border-radius: 5px;
      flex-direction: row;
      align-items: center;
      justify-content: center;
    }

    .md-text--disabled {
      cursor: not-allowed;
    }
  }
`;

export const StyledTableColumnLoader = styled(StyledTableColumn)`
  vertical-align: middle;

  .line {
    display: flex;
    aign-items: center;
  }

  .avatar-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 230px;

    .line {
      margin-left: 16px;
      width: 100%;
    }

    .avatar {
      padding: 5px 0;
    }
  }
`;

const ShoreMoreContainer = styled('tr')`
  .column-content {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 20px;
  }
`;

export const StyledTable = styled(Table)<{ paddingBottom?: string; mobilePaddingBottom?: string }>`
  box-sizing: border-box;
  margin-bottom: ${({ paddingBottom }) => (paddingBottom !== '' ? paddingBottom : '80px')};
  white-space: nowrap;
  table-layout: fixed;
  margin-left: 8px;

  ${({ theme }) => theme.mediaQueries.largeDown} {
    margin-left: unset;
    margin-bottom: ${({ mobilePaddingBottom }) =>
      mobilePaddingBottom !== '' ? mobilePaddingBottom : '140px'}!important;
  }

  &::-webkit-scrollbar {
    height: 8px; /* height of horizontal scrollbar ← You're missing this */
    background-color: #f5f5f5;
    border-radius: 5px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: ${({ theme }) => theme.colors.label};
    border-radius: 50px;
  }

  &.surveys-table {
    padding-bottom: 160px;
  }

  .md-table-row--active {
    background: none;
  }

  .md-table-checkbox {
    width: 40px !important;
    min-width: 40px !important;
    white-space: nowrap;
    padding-left: 0;
  }

  .md-selection-control-container {
    margin: 0;
    width: max-content;
    height: unset;
    color: ${({ theme }) => theme.colors.gray.dark};

    &:hover {
      span {
        color: ${({ theme }) => theme.colors.main};
      }

      .md-selection-control-toggle.md-text--secondary {
        border: 1px solid ${({ theme }) => theme.colors.main};
      }
    }

    .md-icon.material-icons {
      font-size: 16px;
    }

    .md-selection-control-toggle {
      display: flex;
      align-items: center;
      justify-content: center;

      padding: 0;
      margin-right: 16px;
      width: 40px;
      height: 40px;
      box-sizing: border-box;
      border-radius: 5px;
    }
  }
`;

export const StyledTableHead = styled(TableHead)<BoxProps & { width?: string; isBorderHidden?: boolean }>`
  ${compose(space, color, layout, flexbox, backgroundImage, overflow, border, position, typography)}
  tr {
    height: 72px;
    border-bottom: ${({ isBorderHidden, theme }) =>
      isBorderHidden ? 'none' : `1px solid ${theme.colors.gray.medium}`};

    th {
      color: ${({ theme }) => theme.colors.main};
      height: 60px;

      font-weight: 500;
      font-size: 16px;
      line-height: 28px;
      cursor: pointer;
      width: ${({ width }) => width};

      &:first-child {
        border-radius: 2px 0 0 2px;
        padding: 0;
      }

      &:last-child {
        border-radius: 0 2px 2px 0;
      }

      &.md-table-checkbox {
        width: 40px !important;
      }

      .md-icon-text {
        flex-grow: 0;

        &:first-child {
          padding-right: 8px;
        }
      }

      .md-collapser {
        width: 16px;
        height: 7px;
        position: relative;

        &.md-collapser--flipped {
          transform: none;

          img {
            transform: rotate3d(0, 0, 1, 180deg);
          }
        }

        img {
          height: inherit;
          position: absolute;
        }
      }
    }
  }

  &.sticky {
    th {
      position: sticky;
      top: -1;
      z-index: 1;
    }
  }
`;

const StyledTableBody = styled(TableBody)<{ hideTableHeader?: boolean }>`
  .md-table-row {
    &:not(:last-child) {
      border-bottom: 1px solid rgba(0, 0, 0, 0.12) !important;
    }
    &:first-child {
      border-top: ${({ hideTableHeader }) => (hideTableHeader ? 'none' : `1px solid rgba(0, 0, 0, 0.12) !important;`)};
    }

    .more-options {
      width: 60px;

      > div {
        display: flex;
        justify-content: flex-end;
      }
    }
  }

  .md-table-row--hover:not(.custom-extra-row) {
    ${({ theme }) => `
      background: ${theme.colors.gray.light_gray};
    `}
  }

  .custom-extra-row {
    background: unset !important;

    &:nth-child(2) {
      left: -15px;
    }

    & > td {
      padding-right: 0px;
    }
  }
`;

export const StyledTableRow = styled(TableRow)`
  min-height: 60px;
  height: 60px;
  font-size: 16px;
  cursor: pointer;

  ${(props: { danger?: boolean }) =>
    !props?.danger
      ? ''
      : `
      background: #F4E4E4 !important;
      td:not(.more-options) {
        opacity: 0.5 !important;
      }
    `}

  &.table-row-success {
    background: #f3faf7;

    &.table-row-solid {
      background: #f3faf7;
    }
  }

  &.table-row-error {
    background: #fef5fa;

    &.table-row-solid {
      background: #fef5fa;
    }
  }

  &.table-row-warning {
    background: #fcfaf3;

    &.table-row-solid {
      background: #fcfaf3;
    }
  }

  &.table-row-info {
    background: ${({ theme }) => theme.colors.secondaries.blue};

    &.table-row-solid {
      background: ${({ theme }) => theme.colors.secondaries.blue};
    }
  }

  &:not(:first-child) {
    border-bottom: 1px solid ${({ theme }) => theme.colors.gray.medium};
  }

  &:first-child {
    border-top: 1px solid ${({ theme }) => theme.colors.gray.medium};
    border-bottom: 1px solid ${({ theme }) => theme.colors.gray.medium};
  }

  td {
    font-size: 16px;

    &:first-child {
      border-radius: 2px 0 0 2px;
      padding: 0;
    }

    &:last-child {
      border-radius: 0 2px 2px 0;
    }
  }

  &.custom-extra-row {
    td {
      width: fit-content !important;
      overflow: hidden !important;
      white-space: nowrap !important;
    }
  }

  ${({ theme }) => theme.mediaQueries.largeDown} {
    &.custom-extra-row {
      td {
        padding-left: 0;
      }
    }
  }
`;

export const StyledSimpleTableRow = styled.tr`
  min-height: 60px;
  height: 60px;
  font-size: 16px;
  cursor: pointer;

  &:not(.custom-extra-row):hover {
    ${({ theme }) => `
      background: ${theme.colors.gray.light_gray};
    `}
  }

  ${(props: { danger?: boolean }) =>
    !props?.danger
      ? ''
      : `
      background: #F4E4E4 !important;
      td:not(.more-options) {
        opacity: 0.5 !important;
      }
    `}

  &.table-row-success {
    background: #f3faf7;

    &.table-row-solid {
      background: #f3faf7;
    }
  }

  &.table-row-error {
    background: #fef5fa;

    &.table-row-solid {
      background: #fef5fa;
    }
  }

  &.table-row-warning {
    background: #fcfaf3;

    &.table-row-solid {
      background: #fcfaf3;
    }
  }

  &.table-row-info {
    background: ${({ theme }) => theme.colors.secondaries.blue};

    &.table-row-solid {
      background: ${({ theme }) => theme.colors.secondaries.blue};
    }
  }

  &:not(:first-child) {
    border-bottom: 1px solid ${({ theme }) => theme.colors.gray.medium};
  }

  &:first-child {
    border-top: 1px solid ${({ theme }) => theme.colors.gray.medium};
    border-bottom: 1px solid ${({ theme }) => theme.colors.gray.medium};
  }

  td.grouped-header {
    ${({ theme }) => theme.mediaQueries.largeDown} {
      padding-left: 16px !important;
    }
  }

  td {
    font-size: 16px;

    &:first-child {
      border-radius: 2px 0 0 2px;
      padding: 0;
    }

    &:last-child {
      border-radius: 0 2px 2px 0;
    }
  }
`;

const EmptyTableRow = styled.tr`
  td {
    text-align: center;
    font-size: 18px;
    font-weight: 500;
    color: ${({ theme }) => theme.colors.label};
    padding: 20px;
    height: 260px;
  }
`;

DataTable.defaultProps = {
  className: '',
  onRowClick: () => {},
  onRowToggle: () => {},
  selected: [],
  isSelectable: false,
  onSort: () => {},
  sort: [],
  checkedItems: [],
  pageSizes: [10, 50, 100, 250],
};

export default DataTable;
