import { useMemo, useState, ReactNode, useEffect } from 'react';
import capitalize from 'lodash/capitalize';
import { useDispatch } from 'react-redux';
import axios from 'shared/utils/axios';
import {
  showSuccess,
  addToast,
  removeToast,
  hideAllDialogs,
  hideDrawer,
  showBottomNavBar,
} from 'shared/redux/app/reducer';
import axiosLib, { AxiosResponse, AxiosError, AxiosRequestConfig } from 'axios';
import pick from 'lodash/pick';
import { getResponseMessage, getTranslatedWord } from 'shared/utils/tools';

interface MutationParams extends AxiosRequestConfig {
  data?: any;
  method?: 'POST' | 'PUT' | 'DELETE' | 'PATCH' | 'GET';
  onSuccess?: Function;
  onError?: Function;
  onComplete?: Function;
  hideDialog?: boolean;
  showMessage?: boolean;
  message?: string | ReactNode;
  messageBody?: ReactNode;
  subText?: string;
  onMessageDismiss?(): void;
  isBase?: boolean;
  allowCancelation?: boolean;
  autohideTimeout?: number;
  hideOnSuccess?: boolean;
}

interface NodeMutation extends MutationParams {
  node?: string;
}

export type MutationFunctionType = (params?: MutationParams) => Promise<AxiosResponse<any>>;

export default function useMutation(initParams: MutationParams) {
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const [data, setData] = useState<any>();
  const [error, setError] = useState<any>();
  const [source, setSource] = useState<any>({});

  const state = {
    loading,
    data,
    error,
  };

  let cancel: any = null;
  if (source?.cancel) cancel = source?.cancel;

  useEffect(() => {
    return () => {
      if (initParams?.allowCancelation) cancel?.();
    };
  }, [cancel]);

  return [state, mutate, cancel] as const;
  async function mutate(params: MutationParams = {}) {
    const source = axiosLib.CancelToken.source();
    setLoading(true);
    setSource({ ...source });

    const allParams = { ...initParams, ...params };
    const {
      method = 'POST',
      onSuccess = () => {},
      onError = () => {},
      onComplete = () => {},
      onMessageDismiss,
      message,
      messageBody,
      subText,
      hideDialog = true,
      isBase = false,
      autohideTimeout,
      showMessage = true,
      hideOnSuccess = true,
    } = allParams;

    const response: any = await axios({
      ...pick(initParams, ['data', 'method', 'url']),
      ...params,
      cancelToken: source.token,
    }).catch((err: AxiosError) => {
      setData(null);
      const error = {
        ...err.response?.data,
        responseStatus: err?.response?.status,
      };
      onError(error);
      setError(error);
      onComplete(error, null);

      dispatch(removeToast());
      return { error: error };
    });

    setLoading(false);
    if (response && !response.error) {
      setError(null);
      setData(response);
      onSuccess(response);
      onComplete(null, response);

      if (showMessage) {
        dispatch(
          addToast({
            message: message ? getTranslatedWord(String(message)) : getResponseMessage(response, 'success'),
            type: 'success',
          }),
        );
        if (hideOnSuccess) {
          dispatch(hideAllDialogs());
          dispatch(hideDrawer());
        }
      }
    }
    return response;
  }
}

export function useCreateNode(params: NodeMutation) {
  const { node, message = `${capitalize(node)} successfully created`, isBase = true } = params;
  return useMutation({
    ...params,
    message,
    method: 'POST',
    url: `/${node}`,
    isBase,
  });
}

export function useUpdateNode(params: NodeMutation) {
  const { node, message = `${capitalize(node)} successfully updated` } = params;
  return useMutation({
    message,
    method: 'PUT',
    isBase: false,
    ...params,
  });
}

export function useDeleteNode(params: NodeMutation) {
  const { node, message = `${capitalize(node)} successfully deleted` } = params;
  return useMutation({
    ...params,
    message,
    method: 'DELETE',
    url: `/${node}`,
    isBase: true,
  });
}
