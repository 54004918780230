import React, { lazy } from 'react';
import { Redirect } from 'react-router-dom';
import { MANAGER, WORKER, ADMIN } from 'shared/types';

import {
  CAN_CONFIRM_WORK_HOURS,
  CAN_GENERATE_WORKER_REPORTS,
  CAN_MANAGE_WORKSPACES,
  CAN_MANAGE_WORKTYPES,
  CAN_MANAGE_SALARY,
  CAN_MANAGE_BOXES,
  CAN_MANAGE_USERS,
  CAN_MANAGE_USER_WORK_HOURS,
  CAN_REGISTER_OWN_WORK_FROM_COMPUTER,
  CAN_EDIT_OWN_WORK,
  CAN_GENERATE_REPORTS,
  CAN_MANAGE_ADMIN_PAGES,
  HAS_MANAGER_RIGHTS,
} from 'pages/People/model/user_rights';

// auth (guest) pages
const Login = lazy(() => import('pages/Login/containers/LoginPage'));
const ForgotPassword = lazy(() => import('pages/ForgotPassword'));
const SignUp = lazy(() => import('pages/SignUp'));
const Verification = lazy(() => import('pages/Verification'));

// admin
const Admin = lazy(() => import('pages/Admin'));

// main pages
const Dashboard = lazy(() => import('pages/Dashboard'));
const Timesheet = lazy(() => import('pages/Timesheet'));
const Schedules = lazy(() => import('pages/Schedules'));
const Report = lazy(() => import('pages/Report'));
const People = lazy(() => import('pages/People'));
const Workspace = lazy(() => import('pages/Workspace'));

// other pages
const Preference = lazy(() => import('pages/Preference'));
const WorkType = lazy(() => import('pages/WorkType'));
const Box = lazy(() => import('pages/Box'));
const Notifications = lazy(() => import('pages/Notifications'));
const Requests = lazy(() => import('pages/Requests'));
const Profile = lazy(() => import('pages/Profile'));

// conditional
const InnerRange = lazy(() => import('pages/InnerRange'));
const Gateway = lazy(() => import('pages/Geofence'));
const VismaSettings = lazy(() => import('pages/VismaSettings'));

// extra pages
const ErrorPage = lazy(() => import('pages/Errors'));
const RedirectPage = lazy(() => import('pages/Redirect'));
const PdfViewer = lazy(() => import('pages/Report/containers/PdfViewerPage'));

const baseRoutes = [
  //-- for viewing only subject for changes --//
  {
    key: 'index',
    component: () => <Redirect push to="/dashboard" />,
    path: '/',
    exact: true,
    pageProps: {
      permissions: [],
      requireAuth: true,
    },
  },
  {
    key: 'login',
    component: Login,
    path: '/login',
    exact: true,
    pageProps: {
      hasNavigation: false,
      requireAuth: false,
      pageTitle: 'Login',
      pageDescription: 'Login',
      permissions: [],
    },
  },
  {
    key: 'forgot-password',
    component: ForgotPassword,
    path: '/forgot-password',
    pageProps: {
      hasNavigation: false,
      requireAuth: false,
      pageTitle: 'Login',
      pageDescription: 'Login',
      permissions: [],
    },
  },
  {
    key: 'signup',
    component: SignUp,
    path: '/signup',
    pageProps: {
      hasNavigation: false,
      requireAuth: false,
      pageTitle: 'Register',
      pageDescription: 'Register',
      permissions: [],
    },
  },
  {
    key: 'verification',
    component: Verification,
    path: '/verification',
    pageProps: {
      hasNavigation: false,
      requireAuth: false,
      pageTitle: 'Verification',
      pageDescription: 'Verification',
      permissions: [],
    },
  },
  {
    key: 'report',
    component: Report,
    path: '/raport',
    pageProps: {
      hasNavigation: true,
      requireAuth: true,
      pageTitle: 'Raports',
      pageDescription: 'Raports',
      personas: [MANAGER, WORKER],
      permissions: [CAN_GENERATE_REPORTS, CAN_GENERATE_WORKER_REPORTS],
    },
  },
  {
    key: 'box',
    component: Box,
    path: '/box',
    pageProps: {
      hasNavigation: true,
      requireAuth: true,
      pageTitle: 'Boxes',
      pageDescription: 'Boxes',
      personas: [MANAGER],
      permissions: [CAN_MANAGE_BOXES],
    },
  },
  {
    key: 'inner-range',
    component: InnerRange,
    path: '/inner-range',
    pageProps: {
      hasNavigation: true,
      requireAuth: true,
      pageTitle: 'Inner Range',
      pageDescription: 'Inner Range',
      personas: [MANAGER],
      permissions: [HAS_MANAGER_RIGHTS],
    },
  },
  {
    key: 'gateway',
    component: Gateway,
    path: '/gateway',
    pageProps: {
      hasNavigation: true,
      requireAuth: true,
      pageTitle: 'Gateway',
      pageDescription: 'Gateway',
      personas: [MANAGER],
      permissions: [HAS_MANAGER_RIGHTS],
    },
  },
  {
    key: 'notifications',
    component: Notifications,
    path: '/notifications',
    pageProps: {
      hasNavigation: true,
      requireAuth: true,
      pageTitle: 'Notifications',
      pageDescription: 'Notifications',
      personas: [MANAGER],
      permissions: [HAS_MANAGER_RIGHTS],
    },
  },
  {
    key: 'requests',
    component: Requests,
    path: '/requests',
    pageProps: {
      hasNavigation: true,
      requireAuth: true,
      pageTitle: 'Requests',
      pageDescription: 'Requests',
      personas: [WORKER],
      permissions: [],
    },
  },
  {
    key: 'timesheet',
    component: Timesheet,
    path: '/timesheet',
    pageProps: {
      hasNavigation: true,
      requireAuth: true,
      pageTitle: 'Timesheet',
      pageDescription: 'Timesheet',
      personas: [MANAGER, WORKER],
      permissions: [
        CAN_MANAGE_USER_WORK_HOURS,
        CAN_CONFIRM_WORK_HOURS,
        CAN_REGISTER_OWN_WORK_FROM_COMPUTER,
        CAN_EDIT_OWN_WORK,
      ],
    },
  },
  {
    key: 'worktype',
    component: WorkType,
    path: '/worktype',
    pageProps: {
      hasNavigation: true,
      requireAuth: true,
      pageTitle: 'Work types',
      pageDescription: 'Work types',
      personas: [MANAGER],
      permissions: [CAN_MANAGE_WORKTYPES],
    },
  },
  {
    key: 'visma',
    component: VismaSettings,
    path: '/visma',
    pageProps: {
      hasNavigation: true,
      requireAuth: true,
      pageTitle: 'Visma Settings',
      pageDescription: 'Visma Settings',
      personas: [MANAGER],
      permissions: [CAN_MANAGE_SALARY],
    },
  },
  {
    key: 'dashboard',
    component: Dashboard,
    path: '/dashboard',
    pageProps: {
      hasNavigation: true,
      requireAuth: true,
      pageTitle: 'Dashboard',
      pageDescription: 'Dashboard',
      personas: [MANAGER, WORKER],
      permissions: [
        CAN_MANAGE_USER_WORK_HOURS,
        CAN_CONFIRM_WORK_HOURS,
        CAN_REGISTER_OWN_WORK_FROM_COMPUTER,
        CAN_EDIT_OWN_WORK,
      ],
    },
  },
  {
    key: 'preference',
    component: Preference,
    path: '/preference',
    pageProps: {
      hasNavigation: true,
      requireAuth: true,
      pageTitle: 'Preference',
      pageDescription: 'Preference',
      personas: [MANAGER, WORKER],
      permissions: [],
    },
  },
  {
    key: 'schedules',
    component: Schedules,
    path: '/schedules',
    pageProps: {
      hasNavigation: true,
      requireAuth: true,
      pageTitle: 'Schedules',
      pageDescription: 'Schedules',
      personas: [MANAGER, WORKER],
      permissions: [],
    },
  },
  {
    key: 'admin',
    component: Admin,
    path: '/admin',
    pageProps: {
      hasNavigation: true,
      requireAuth: true,
      pageTitle: 'Admin',
      pageDescription: 'Admin',
      personas: [ADMIN],
      permissions: [CAN_MANAGE_ADMIN_PAGES],
    },
  },
  {
    key: 'workspace',
    component: Workspace,
    path: '/workspace',
    pageProps: {
      hasNavigation: true,
      requireAuth: true,
      pageTitle: 'Workspaces',
      pageDescription: 'Workspaces',
      personas: [MANAGER],
      permissions: [CAN_MANAGE_WORKSPACES],
    },
  },
  {
    key: 'people',
    component: People,
    path: '/people',
    pageProps: {
      hasNavigation: true,
      requireAuth: true,
      pageTitle: 'People',
      pageDescription: 'People',
      personas: [MANAGER],
      permissions: [CAN_MANAGE_USERS],
    },
  },
  {
    key: 'profile',
    component: Profile,
    path: '/profile',
    pageProps: {
      hasNavigation: true,
      requireAuth: true,
      pageTitle: 'Profile',
      pageDescription: 'Profile',
      personas: [WORKER],
      permissions: [],
    },
  },
  {
    key: 'language',
    component: () => <Redirect push to="/login" />,
    path: '/language',
    pageProps: {
      permissions: [],
      requireAuth: false,
    },
  },
  {
    key: 'redirect',
    path: '/redirect',
    component: RedirectPage,
    pageProps: {
      hasNavigation: false,
      requireAuth: 'optional',
      pageTitle: 'Redirect',
      pageDescription: 'redirect',
      permissions: [],
    },
  },
  {
    key: 'error',
    path: '/error/:status?',
    component: ErrorPage,
    pageProps: {
      hasNavigation: false,
      requireAuth: 'optional',
      pageTitle: 'Error',
      pageDescription: 'error',
      permissions: [],
    },
  },
  {
    key: 'report-pdf-viewer',
    path: '/report-pdf-viewer',
    component: PdfViewer,
    pageProps: {
      hasNavigation: false,
      requireAuth: true,
    },
  },
  { key: '404', component: () => <Redirect push to="/error/404" /> },
];

export default baseRoutes;

export function getRoutes(persona: string) {
  return baseRoutes;
}
