import React, { useState, useEffect, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { pick, omit } from 'lodash';

import DrawerLayout, { DrawerLayoutProps } from 'shared/components/Layout/Drawer';

import { GlobalState } from 'shared/redux/reducer';
import { hideDrawer, drawerProcessing } from 'shared/redux/app/reducer';
import { clearClickedEvents, clearCopiedEvents } from 'shared/redux/schedule/reducer';

import useForm, { UseFormParams, FormState, FormHandlers } from 'shared/hooks/useForm';
import { useBreakpoint } from 'shared/hooks/useBreakpoint';

import getDisplayName from './getDisplayName';

const dialogProps = [
  'dialogId',
  'labelId',
  'dialogActionsRenderer',
  'dialogTitleRenderer',
  'title',
  'subtitle',
  'dialogContainerClassName',
  'dialogClassName',
  'cancelLabel',
  'extraLabel',
  'continueLabel',
  'hasExtra',
  'hasCancel',
  'hasContinue',
  'titleClassName',
  'contentClassName',
  'footerClassName',
  'minimize',
  'context',
  '$noBottom',
  '$withBottomMargin',
  'isDisabled',
  'disableDefaultAction',
];
const formProps = ['initialFields', 'validator', 'customChangeHandler', 'onValid', 'onSetForm'];

export type WithDrawerProps<T> = DrawerLayoutProps &
  UseFormParams<T> & {
    continueIsDisabled?(form: T): boolean;
    isFilterDrawer?: boolean;
    isDisabled?: boolean;
  };

export type WrappedProps<T> = Pick<DrawerLayoutProps, 'onCancel' | 'onContinue' | 'onClose' | 'onExtra'> & {
  formState: FormState<T>;
  formHandlers: FormHandlers;
};

export type DrawerContentProps<T> = {
  formState: FormState<T>;
  formHandlers: FormHandlers;
};

export default () => {
  return function<T>(WrappedComponent: React.FC<WrappedProps<T>>) {
    function Drawer(props: WithDrawerProps<T>) {
      const dispatch = useDispatch();

      const isProcessing = useSelector((state: GlobalState) => state.app.drawerProcessing);
      const role_level = useSelector((state: GlobalState) => state.auth.user?.role_level);
      const [visible, setVisible] = useState(false);
      const [rendered, setRendered] = useState(false);

      const breakPoint = useBreakpoint();

      const { initialFields } = props;
      const [formState, formHandlers] = useForm<T>({ ...pick(props, formProps), onValid, initialFields });
      const { title, isDisabled } = props;

      const isDesktop = useMemo(() => {
        return breakPoint === 'desktop';
      }, [breakPoint]);

      useEffect(() => {
        if (!visible && !rendered) {
          setRendered(true);
          setTimeout(() => setVisible(true), 0);
          document.body.classList.add('no-scroll');
        }
      }, [visible, rendered, isDesktop]);

      useEffect(() => {
        setTimeout(() => {
          dispatch(clearClickedEvents());
          dispatch(clearCopiedEvents());
        }, 0);

        return () => {
          document.body.classList.remove('no-scroll');
        };
      }, []);

      return (
        <DrawerLayout
          {...pick(props, dialogProps)}
          title={title}
          onContinue={props.onContinue || onContinue}
          onCancel={onCancel}
          onExtra={props.onExtra || onExtra}
          onClose={props.onClose || onCancel}
          onMinimize={props.onMinimize || onMinimize}
          isProcessing={isProcessing}
          role_level={role_level}
          continueIsDisabled={continueIsDisabled()}
          visible={visible}
          formHandlers={formHandlers}
        >
          <WrappedComponent
            formState={formState}
            formHandlers={formHandlers}
            onContinue={onContinue}
            onExtra={props.onExtra || onExtra}
            onCancel={onCancel}
            onClose={onCancel}
            {...omit(props, dialogProps.concat(formProps))}
          />
        </DrawerLayout>
      );

      function continueIsDisabled() {
        const {
          continueIsDisabled = () => {
            return isDisabled ? isDisabled : false;
          },
        } = props;
        return continueIsDisabled(formState.fields);
      }

      function onValid(arg: T & { division_ids?: string[] }) {
        dispatch(drawerProcessing(true));
        props.onValid && props.onValid(arg, props.closeOnValid && onCancel);
      }
      function onContinue() {
        if (!isProcessing) {
          formHandlers.onValidate();
        }
      }
      function onExtra() {
        if (!isProcessing) {
          formHandlers.onValidate();
        }
      }
      function onCancel() {
        setVisible(false);
        setTimeout(() => {
          dispatch(hideDrawer());
          props.onCancel && props.onCancel();

          if (!isDesktop) {
          }
        }, 300);
      }

      function onMinimize() {}
    }
    Drawer.displayName = `withDrawer(${getDisplayName(WrappedComponent)})`;
    return Drawer;
  };
};
