import get from 'lodash/get';
import React, { ReactNode, Dispatch, SetStateAction } from 'react';
import Button from 'react-md/lib/Buttons/Button';
import TableColumn from 'react-md/lib/DataTables/TableColumn';
import styled from 'styled-components';
import MoreOption from './MoreOption';
import { TableAction } from 'shared/components/DataTable/tableReducer';
import { Identifiable } from 'shared/types';

import { LoadingSkeleton } from './styledComponents';

export enum columnTyping {
  function = 'function',
  actions = 'actions',
  component = 'component',
  value = 'value',
  'more-options' = 'more-options',
}

export type ColumnType<T = any> = {
  title?: string;
  accessor?: string;
  type?: columnTyping;
  headProps?: object;
  component?: React.FC<any>;
  componentProps?: object;
  bodyProps?: object;
  fn?: (row: T, index: number) => React.ReactNode;
  actions?: ActionType<T>[];
  sortable?: boolean;
  adjusted?: boolean;
  key?: string;
  width?: string;
  sortKey?: string;
  conditionalRendering?: (arg: T) => boolean;
};

export type ActionType<T> = {
  label: string | ((row: any) => string);
  className?: string;
  icon?: string | ReactNode;
  onClick?: (row: T) => void;
  type?: 'button' | 'component';
  conditionalRendering?: (arg: T) => boolean;
  component?: React.FC<Pick<ActionType<T>, 'icon' | 'label' | 'onClick'> & { row: T }>;
  color?: string | ((row: any) => string);
};

export type ColumnProp<T extends Identifiable> = {
  row: T;
  index: number;
  column: ColumnType<T>;
  rowLength?: number;

  expand: boolean;
  expandToggle: Dispatch<SetStateAction<boolean>>;
  editedRowIndex?: number | null;
  useReactMdTableColumn?: boolean;
};

function Column<T extends Identifiable>(props: ColumnProp<T>) {
  const {
    row,
    column,
    index,
    expandToggle,
    expand,
    rowLength = 0,
    useReactMdTableColumn = true,
    editedRowIndex,
  } = props;
  const {
    type = 'default',
    accessor = '',
    bodyProps = {},
    actions = [],
    component: Cell = () => null,
    fn = () => null,
    componentProps = {},
    title,
    adjusted = false,
    width,
    conditionalRendering,
  } = column;
  let children;
  let newBodyProps = bodyProps;

  if (type === 'actions') {
    children = actions.map(
      ({
        label,
        className,
        icon,
        onClick = () => {},
        type: actionType = 'button',
        component: Action = () => null,
        conditionalRendering = arg => true,
      }) => {
        if (!conditionalRendering(row)) {
          return null;
        }
        if (actionType === 'component') {
          return <Action key={JSON.stringify(label)} row={row} label={label} icon={icon} onClick={onClick} />;
        }
        return (
          <Button
            icon
            children={icon}
            tooltipLabel={label}
            key={JSON.stringify(label)}
            className={className}
            onClick={e => {
              e.stopPropagation();
              onClick(row);
            }}
          />
        );
      },
    );
  } else if (type === ('more-options' as columnTyping)) {
    newBodyProps = { ...newBodyProps, className: 'more-options', adjusted: false };
    children = <MoreOption row={row} actions={actions} index={index} rowLength={rowLength} />;

    /*<MoreOptions row={row} actions={actions} />*/
  } else if (type === 'component') {
    children = <Cell index={index} row={row} {...componentProps} expand={expand} expandToggle={expandToggle} />;
  } else if (type === 'function') {
    children = fn(row, index);
  } else {
    children = get(row, accessor);
  }

  if (conditionalRendering && !conditionalRendering(row))
    return useReactMdTableColumn ? (
      <StyledTableColumn adjusted={adjusted} {...newBodyProps} width={width} />
    ) : (
      <div {...newBodyProps}>{children}</div>
    );

  return useReactMdTableColumn ? (
    <StyledTableColumn adjusted={adjusted} {...newBodyProps} width={width}>
      {children}
    </StyledTableColumn>
  ) : (
    children
  );
}

export const StyledTableColumn = styled(TableColumn)<{ width?: string }>`
  color: ${({ theme }) => theme.colors.gray.dark};
  width: ${({ width }) => width};
`;

export default Column;
